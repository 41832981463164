import { combineReducers } from 'redux'
import InsuranceReducer from './_insurance'
import TestimonyReducer from './_testimony'
import UserReducer from './_user'
import AgencyReducer from './_agency'
import NewsReducer from './_news'
import CampaignReducer from './_campaign'
import HistoricalReducer from './_historical'

export default combineReducers({
    historical: HistoricalReducer,
    news: NewsReducer,
    campaign: CampaignReducer,
    insurance: InsuranceReducer,
    testimony: TestimonyReducer,
    agency: AgencyReducer,
    UserReducer
});
