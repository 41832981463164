import { handleActions } from 'redux-actions';

const initialState = {
  agency: null
};

const HistoricalReducer = handleActions({
	HISTORICAL_LOAD_OK: {
		next: (state, action) => ({
			...state, agency: action.payload
		}),
		throw: (state) => ({
			...state, error: true
		}),
  },
}, initialState);


export default HistoricalReducer;
