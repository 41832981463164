import FirestoreApi from './firestore-api';

class Api {
  static API_TYPES = {
    FIREBASE: 'FIREBASE',
    REST:'REST'
  }

  static createFactory(type){
    switch (type) {
      case Api.API_TYPES.FIREBASE:
        return new FirestoreApi()
      default:
        return new FirestoreApi()
    }
  }
}
const api = Api.createFactory(process.env.REACT_APP_APIFACTORY)

export default api;