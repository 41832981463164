export default [
  {
    id: 1,
    insurance:'proteccion-negocio',
    "client": "Cliente de Protección Negocio",
    "description": `
        En Semana Santa del 2019, hubo un incendio en Mesa Redonda y Diego perdió toda su mercadería, 
        pero el seguro se hizo efectivo, le pagó la pérdida y eso lo ayudó para que pueda seguir creciendo. 
        <br/><br/>  
        Con MiBanco, Diego obtuvo el primer crédito para su negocio de ventas al por mayor y menor ubicado 
        en los alrededores de Mesa Redonda.
    `,
    "firstname": "Diego",
    "image": "testimonio-diego",
    "lastname": "Mamani",
    "testimony": "El seguro fue efectivo y me pagaron la indemnización. Se los sugiero y se los recomiendo, porque uno no sabe qué pueda pasar más adelante.",
    "video": "testimonio_diego.webm"
  },
  {
    id: 3,
    insurance:'oncorespaldo',
    "client": "Cliente de Onco Respaldo",
    "description": `
      Nicolasa fue a su control médico y, lamentablemente, le diagnósticaron cáncer. Una de sus preocupaciones 
      eran los costos del tratamiento, pero gracias al Microseguro Onco Respaldo, le ayudó con los gastos necesarios 
      para que así pueda seguir adelante.
      <br/><br/>
      Ella es Técnica de Enfermería y vive en la provincia de Moquegua. Adquirió este seguro a modo de prevención 
      al momento de pedir un préstamo a MiBanco, y está muy agradecida por la ayuda que ha recibido.
    `,
    "firstname": "Nicolasa",
    "image": "testimonio-nicolasa",
    "lastname": "Medina",
    "testimony": "Estoy muy agradecida. Me ayudaron bastante con mis gastos y consultas particulares.",
    "video": "testimonio_nicolasa.webm"
  }
]
