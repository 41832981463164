import { handleActions } from 'redux-actions';
import insurances from '../../constants/data/insurances';

const initialState = {
	insurance: insurances[0]
};
const InsuranceReducer = handleActions({
	SELECT_INSURANCE:  (state, action) => ({
        ...state, insurance: insurances[action.payload]
    })
}, initialState);


export default InsuranceReducer;