import {createActions} from 'redux-actions';

export const HISTORICAL_LOAD = 'HISTORICAL_LOAD';
export const HISTORICAL_LOAD_OK = 'HISTORICAL_LOAD_OK';

const actionsCreator = createActions(
  {
    HISTORICAL_LOAD: (code) => ({ code }),
  },
  HISTORICAL_LOAD_OK,
);

export const {
  historicalLoad,
  historicalLoadOk,
} = actionsCreator;