import {createActions} from 'redux-actions';

export const AGENCY_LOAD = 'AGENCY_LOAD';
export const AGENCY_LOAD_OK = 'AGENCY_LOAD_OK';

export const AGENCY_LOAD_RANKING = 'AGENCY_LOAD_RANKING';
export const AGENCY_LOAD_RANKING_OK = 'AGENCY_LOAD_RANKING_OK';


const actionsCreator = createActions(
  {
    AGENCY_LOAD: (code) => ({ code }),
    AGENCY_LOAD_RANKING: (filters, comparator, zone) => ({ filters, comparator, zone }),
  },
  AGENCY_LOAD_OK,
  AGENCY_LOAD_RANKING_OK
);

export const {
  agencyLoad,
  agencyLoadOk,
  agencyLoadRanking,
  agencyLoadRankingOk,
} = actionsCreator;
