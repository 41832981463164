import React, {lazy, Suspense} from 'react';
import { Route, Switch } from 'react-router-dom';
import {
    ROUTE_INSURANCE,
    ROUTE_DEFAULT,
} from './constants/routes/routes-ui';
import Loading from './components/Loading';

const Home = lazy(() => import('./containers/home/Home'));
const Insurance = lazy(() => import('./containers/insurance/Insurance'));

const routes = (
  <main>
    <Suspense fallback={<Loading/>}>
      <Switch>
        <Route exact path={ROUTE_DEFAULT} component={() => <Home />} />
        <Route exact path={ROUTE_INSURANCE} component={() => <Insurance/>} />
      </Switch>
    </Suspense>
  </main>
);

export default routes;
