import firebase from 'firebase/app';
import 'firebase/firestore';

import { from } from 'rxjs';
import config from '../../config/firebase';

const operators = {
  gte: '>=',
  gt: '>',
  lt: '<',
	lte: '<=',
	contains: 'array-contains'
}
class FirestoreApi {
  constructor() {
    this.app = firebase.initializeApp(config);
    this.db = firebase.firestore();
  }

  get (path, {filters = null, orderBy = null, limit = 0}) {
    const docs = path.split('/');
    let query = this.db.collection(docs.shift());

    if (docs.length) docs.forEach((doc) => { query = query.doc(doc) });

    if (filters) {
      Object.keys(filters).forEach((name) => {
        if (typeof filters[name] === 'object') {
          const [operator] = Object.keys(filters[name]);
          query = query.where(name, operators[operator], filters[name][operator]);
        } else {
          const operator = '==';
          query = query.where(name, operator, filters[name]);
        }
      });
    }

    if (orderBy) {
      Object.keys(orderBy).forEach(property => {
        query = query.orderBy(property, orderBy[property]);
      })
    }

    if (limit > 0) {
      query = query.limit(limit);
    }

    return from(query.get()
      .then(querySnapshot => {
        if (querySnapshot.exists) {
          return  {
            id: querySnapshot.id,
            ...querySnapshot.data()
          };
        }

        const data = [];
        querySnapshot.forEach((doc) => {
          data.push({id: doc.id, ...doc.data()})
        });

        return data;
      })
    );
  }

  set (collection, data) {
    const firestore = this.db

    return from(
      Promise.all(
        data.map(query => 
          firestore.collection(collection).doc(query.doc).set(query.value, { merge: true })
        )
      )
      .then(() => {
        return ("Document successfully written!");
      })
      .catch((error) => {
        return ("Error writing document: ", error);
      })
    )
  }
}
export default FirestoreApi;
