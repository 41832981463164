import { handleActions } from 'redux-actions';

const initialState = {
  list: [],
  pending: false,
};

const CampaignReducer = handleActions({
	CAMPAIGN_LOAD_OK: {
		next: (state, action) => ({
      ...state,
      list: action.payload
		}),
		throw: (state) => ({
      ...state,
      pending: false,
      error: true
		}),
  },
}, initialState);


export default CampaignReducer;
