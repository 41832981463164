import { combineEpics } from 'redux-observable'
import AgencyEpics from './_agency.epic'
import UserEpics from './_user.epic'
import NewsEpics from './_news.epic'
import CampaignEpics from './_campaign.epic'
import HistoricalEpics from './_historical.epic'


const rootEpics =  combineEpics(
  NewsEpics,
  CampaignEpics,
  UserEpics,
  AgencyEpics,
  HistoricalEpics
)
export default rootEpics;
