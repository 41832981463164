import { ofType } from "redux-observable"
import { merge, of } from 'rxjs'
import { switchMap, catchError, map } from 'rxjs/operators'
import {
  HISTORICAL_LOAD,
  historicalLoadOk
} from '../actions/historical'
import UserApi from '../../api/user.api'

const HistoricalLoadEpic = action$ => action$.pipe(
  ofType(HISTORICAL_LOAD),
  switchMap(({ payload }) => {
    const api = new UserApi();
    return api.historical(payload.code)
      .pipe(
        map(agency => {
          return historicalLoadOk(agency)
        }),
        catchError(error => {
          return of(historicalLoadOk(error))
        })
      )
  })
)


export default function HistoricalEpics(action$){
  return merge(
    HistoricalLoadEpic(action$),
  )
};
