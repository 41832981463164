import { ofType } from "redux-observable"
import { merge, of } from 'rxjs'
import { switchMap, catchError, map, mergeMap } from 'rxjs/operators'
import {
  USER_LOAD,
  userLoadOk,
  USER_LOAD_RANKING,
  userLoadRankingOk,
  USER_LOAD_LOW_EFECTIVITY,
  userLoadLowEfectivityOk,
  USER_LOAD_CONFIG_TEAM,
  userLoadConfigTeamOk,
  USER_LOAD_LEADER_TEAM,
  userLoadLeaderTeamOk,
  USER_SAVE_FILTERS,
  userSaveFiltersOk
} from '../actions/user'
import UserApi from '../../api/user.api'
import Analytics from '../../lib/Analytics'

const userLoadEpic = action$ => action$.pipe(
  ofType(USER_LOAD),
  switchMap(({ payload }) => {
    Analytics.login(payload.agency, payload.code)
    const api = new UserApi();
    return api.me(payload.agency, payload.code, payload.collection)
      .pipe(
        map(user => {
          return userLoadOk(user)
        }),
        catchError(error => {
          return of(userLoadOk(error))
        })
      )
  })
)

const Compare = (indicator) => (
  (a, b) => {
    if (a[indicator] < b[indicator])
      return 1;
    if (a[indicator] > b[indicator])
      return -1;
    return 0;
  }
)

const userLoadRankingEpic = action$ => action$.pipe(
  ofType(USER_LOAD_RANKING),
  switchMap(({ payload }) => {
    const api = new UserApi()
    const { filters, comparator } = payload

    let getCompare = Compare('efectivity')
    let filtersList = {}

    if (comparator) {
      getCompare = Compare(comparator)
    }

    if (filters) {
      filtersList = filters
    }

    return api.ranking(filtersList).pipe(
      map(users => users.sort(getCompare)),
      map(currentUser => {
        return userLoadRankingOk(currentUser)
      }),
      catchError(error => {
        return of(userLoadRankingOk(error))
      })
    )
  })
)

const userLoadLowEfectivityEpic = action$ => action$.pipe(
  ofType(USER_LOAD_LOW_EFECTIVITY),
  switchMap(({ payload }) => {
    const api = new UserApi()
    const getCompare = Compare('efectivity')

    return api.ranking(payload.filters).pipe(
      map(users => users.sort(getCompare)),
      map(currentUser => {
        return userLoadLowEfectivityOk(currentUser)
      }),
      catchError(error => {
        return of(userLoadLowEfectivityOk(error))
      })
    )
  })
)

const userLoadConfigTeam = action$ => action$.pipe(
  ofType(USER_LOAD_CONFIG_TEAM),
  switchMap(({ payload }) => {
    const api = new UserApi()
    const getCompare = Compare('efectivity')
    const { collection, data, agency } = payload

    return api.configTeam(collection, data).pipe(
      mergeMap(() => {
        return api.ranking({ agency })
        .pipe(
          map(users => users.sort(getCompare)),
          map(currentUser => {
            return userLoadConfigTeamOk(currentUser)
          })
        )
      }),
      catchError(error => {
        return of(userLoadConfigTeamOk(error))
      })
    )
  })
)

const userLoadLeaderTeam = action$ => action$.pipe(
  ofType(USER_LOAD_LEADER_TEAM),
  switchMap(({ payload }) => {
    const api = new UserApi()
    const { collection, data } = payload

    return api.configTeam(collection, data).pipe(
      map(res => {
        return userLoadLeaderTeamOk(res)
      }),
      catchError(error => {
        return of(userLoadLeaderTeamOk(error))
      })
    )
  })
)

const userSaveFilters = action$ => action$.pipe(
  ofType(USER_SAVE_FILTERS),
  switchMap(({ payload }) => {
    const api = new UserApi()
    const { data } = payload

    return api.saveFilters(data).pipe(
      map(() => {
        return userSaveFiltersOk(data.value)
      }),
      catchError(error => {
        return of(userSaveFiltersOk(error))
      })
    )
  })
)

export default function UserEpics(action$){
  return merge(
    userLoadEpic(action$),
    userLoadRankingEpic(action$),
    userLoadLowEfectivityEpic(action$),
    userLoadConfigTeam(action$),
    userLoadLeaderTeam(action$),
    userSaveFilters(action$),
  )
};
