import React from 'react';
import { Provider } from 'react-redux'
import ReactDOM from 'react-dom'
import LogRocket from 'logrocket'
import { HashRouter as Router } from 'react-router-dom'
import routes from './routes'
import store from './redux/store'
import Analytics from './lib/Analytics'
import './index.scss'
import './styles/torito-icons.css'

LogRocket.init('aj0tbg/ayllu')
Analytics.initialize()

ReactDOM.render(
  <Provider store={store}>
    <Router>
      {routes}
    </Router>
  </Provider>,
  document.getElementById('root')
);
