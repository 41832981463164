import api from './factory'

export default class UserApi {
  constructor() {
    this.parameters = {
      collection: {
        agencies: 'agencies',
        users: 'users',
        leaders: 'leaders',
        news: 'news',
        campaigns: 'campaigns',
        historical: 'historical'
      }
    };
    this.api = api
  }

  me(agency, code, collection='users') {
    return this.api.get(`${collection}/${agency}-${code}`, {})
  }

  agency(code) {
    const { collection } = this.parameters
    return this.api.get(`${collection.agencies}/${code}`, {})
  }

  ranking(filters) {
    const { collection } = this.parameters
    return this.api.get(collection.users, { filters })
  }

  news() {
    const { collection } = this.parameters;
    return this.api.get(collection.news, { filter: null, orderBy: { date: "desc" }, limit: 6 })
  }

  campaigns() {
    const { collection } = this.parameters;
    return this.api.get(collection.campaigns, {})
  }

  historical(code) {
    const { collection } = this.parameters;
    return this.api.get(`${collection.historical}/${code}`, {})
  }

  agencyRanking(filters) {
    const { collection } = this.parameters;
    return this.api.get(collection.agencies, { filters })
  }

  lowEfectivity(agency) {
    const { collection } = this.parameters;
    const filters = {
      agency,
      efectivity: 0.0
    }
    return this.api.get(collection.users, { filters })
  }

  configTeam(collection, data) {
    return this.api.set(collection, data)
  }

  saveFilters(data) {
    const { collection } = this.parameters;
    return this.api.set(collection.leaders, data)
  }
}
