import { handleActions } from 'redux-actions';

const initialState = {
  agency: null,
  ranking: [],
};

const AgencyReducer = handleActions({
	AGENCY_LOAD_OK: {
		next: (state, action) => ({
			...state, agency: action.payload
		}),
		throw: (state) => ({
			...state, error: true
		}),
  },
	AGENCY_LOAD_RANKING_OK: {
		next: (state, action) => ({
			...state, ranking: action.payload
		}),
		throw: (state) => ({
			...state, error: true
		}),
  },
}, initialState);


export default AgencyReducer;
