import { createActions } from 'redux-actions';

export const USER_LOAD = 'USER_LOAD';
export const USER_LOAD_OK = 'USER_LOAD_OK';

export const USER_LOAD_RANKING = 'USER_LOAD_RANKING';
export const USER_LOAD_RANKING_OK = 'USER_LOAD_RANKING_OK';

export const USER_LOAD_LOW_EFECTIVITY = 'USER_LOAD_LOW_EFECTIVITY';
export const USER_LOAD_LOW_EFECTIVITY_OK = 'USER_LOAD_LOW_EFECTIVITY_OK';

export const USER_LOAD_CONFIG_TEAM = 'USER_LOAD_CONFIG_TEAM';
export const USER_LOAD_CONFIG_TEAM_OK = 'USER_LOAD_CONFIG_TEAM_OK';

export const USER_LOAD_LEADER_TEAM = 'USER_LOAD_LEADER_TEAM';
export const USER_LOAD_LEADER_TEAM_OK = 'USER_LOAD_LEADER_TEAM_OK';

export const USER_SAVE_FILTERS = 'USER_SAVE_FILTERS';
export const USER_SAVE_FILTERS_OK = 'USER_SAVE_FILTERS_OK';

const actionsCreator = createActions(
  {
    USER_LOAD: (agency, code, collection) => ({ agency, code, collection }),
    USER_LOAD_RANKING: (filters, comparator, view, agency) => ({ filters, comparator, view, agency }),
    USER_LOAD_LOW_EFECTIVITY: (filters) => ({ filters }),
    USER_LOAD_CONFIG_TEAM: (collection, data, agency) => ({ collection, data, agency }),
    USER_LOAD_LEADER_TEAM: (collection, data) => ({ collection, data }),
    USER_SAVE_FILTERS: (data) => ({ data })
  },
  USER_LOAD_OK,
  USER_LOAD_RANKING_OK,
  USER_LOAD_LOW_EFECTIVITY_OK,
  USER_LOAD_CONFIG_TEAM_OK,
  USER_LOAD_LEADER_TEAM_OK,
  USER_SAVE_FILTERS_OK,
)

export const {
  userLoad,
  userLoadOk,
  userLoadRanking,
  userLoadRankingOk,
  userLoadLowEfectivity,
  userLoadLowEfectivityOk,
  userLoadConfigTeam,
  userLoadConfigTeamOk,
  userLoadLeaderTeam,
  userLoadLeaderTeamOk,
  userSaveFilters,
  userSaveFiltersOk,
} = actionsCreator;
