import { handleActions } from 'redux-actions';

const initialState = {
  list: [],
  pending: false,
};

const NewsReducer = handleActions({
	NEWS_LOAD_OK: {
		next: (state, action) => ({
      ...state,
      list: action.payload
		}),
		throw: (state) => ({
      ...state,
      pending: false,
      error: true
		}),
  },
}, initialState);


export default NewsReducer;
