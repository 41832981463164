import { ofType } from "redux-observable"
import { merge, of } from 'rxjs'
import { switchMap, catchError, map } from 'rxjs/operators'
import {
  CAMPAIGN_LOAD,
  campaignLoadOk,
} from '../actions/campaign'
import UserApi from '../../api/user.api'

const CampaignLoadEpic = action$ => action$.pipe(
  ofType(CAMPAIGN_LOAD),
  switchMap(() => {
    const api = new UserApi();
    return api.campaigns()
      .pipe(
        map(campaign => {
          return campaignLoadOk(campaign)
        }),
        catchError(error => {
          return of(campaignLoadOk(error))
        })
      )
  })
)



export default function CampaignEpics(action$){
  return merge(
    CampaignLoadEpic(action$),
  )
};
