import ReactGA from 'react-ga';

export default class Analytics {
	static debug = process.env.NODE_ENV === 'development';

	static initialize() {
		ReactGA.initialize(process.env.REACT_APP_GOOGLE_ANALYTICS, {
			debug: Analytics.debug
		});
	}

	static login(agency, code) {
		const userId = `${agency}-${code}`;
		ReactGA.set({ userId });
	}

	static pageView() {
		ReactGA.pageview(window.location.hash);
	}

	static customPageView(page) {
		ReactGA.pageview(`#/${page}`);
	}

	static modalView(modal) {
		ReactGA.modalview(modal);
	}

	static event(action, { category, label, value }) {
		ReactGA.event({
			category,
			action,
      label,
      value
		});
	}
}
