import { ofType } from "redux-observable"
import { merge, of } from 'rxjs'
import { switchMap, catchError, map } from 'rxjs/operators'
import {
  AGENCY_LOAD,
  agencyLoadOk,
  AGENCY_LOAD_RANKING,
  agencyLoadRankingOk,
} from '../actions/agency'
import UserApi from '../../api/user.api'

const AgencyLoadEpic = action$ => action$.pipe(
  ofType(AGENCY_LOAD),
  switchMap(({ payload }) => {
    const api = new UserApi();
    return api.agency(payload.code)
      .pipe(
        map(agency => {
          return agencyLoadOk(agency)
        }),
        catchError(error => {
          return of(agencyLoadOk(error))
        })
      )
  })
)


const Compare = (indicator) => (
  (a, b) => {
    if (a[indicator] < b[indicator])
      return 1;
    if (a[indicator] > b[indicator])
      return -1;
    return 0;
  }
)

const agencyLoadRankingEpic = action$ => action$.pipe(
  ofType(AGENCY_LOAD_RANKING),
  switchMap(({ payload }) => {
    const api = new UserApi()
    const { filters, comparator, zone } = payload

    let filtersList = { zone }
    let getCompare = Compare('efectivity')

    if (comparator) {
      getCompare = Compare(comparator.indicator)
    }

    if (filters) {
      filtersList = filters
    }


    return api.agencyRanking(filtersList).pipe(
      map(agencies => {
        return agencies.sort(getCompare)
      }),
      map(currentAgency => {
        return agencyLoadRankingOk(currentAgency)
      }),
      catchError(error => {
        return of(agencyLoadRankingOk(error))
      })
    )
  })
)


export default function AgencyEpics(action$){
  return merge(
    AgencyLoadEpic(action$),
    agencyLoadRankingEpic(action$),
  )
};
