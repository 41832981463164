import { createActions } from 'redux-actions';

export const CAMPAIGN_LOAD = 'CAMPAIGN_LOAD';
export const CAMPAIGN_LOAD_OK = 'CAMPAIGN_LOAD_OK';


const actionsCreator = createActions(
  CAMPAIGN_LOAD,
  CAMPAIGN_LOAD_OK,
);

export const {
  campaignLoad,
  campaignLoadOk,
} = actionsCreator;
