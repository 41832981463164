import { handleActions } from 'redux-actions';

const initialState = {
	user: null,
  ranking: [],
  assessors: [],
	low_efectivity: [],
	pending: false,
};

const UserReducer = handleActions({
	USER_LOAD_OK: {
		next: (state, action) => ({
			...state, user: action.payload
		}),
		throw: (state) => ({
			...state, error: true
		}),
	},
	USER_LOAD_RANKING_OK: {
		next: (state, action) => ({
			...state, ranking: action.payload, assessors: action.payload
		}),
		throw: (state) => ({
			...state, error: true
		}),
  },
  USER_LOAD_LOW_EFECTIVITY_OK: {
		next: (state, action) => ({
			...state, low_efectivity: action.payload
		}),
		throw: (state) => ({
			...state, error: true
		}),
	},
	USER_LOAD_CONFIG_TEAM: {
		next: (state) => ({
			...state,
			pending: true
		}),
		throw: (state) => ({
			...state, error: true
		}),
	},
	USER_LOAD_CONFIG_TEAM_OK: {
		next: (state, action) => ({
			...state,
			pending: false,
			ranking: action.payload,
			user: Object.assign(state.user, { team: true })
		}),
		throw: (state) => ({
			...state, error: true
		}),
	},
	USER_SAVE_FILTERS_OK: {
		next: (state, action) => ({
			...state,
			user: Object.assign(state.user, action.payload)
		}),
		throw: (state) => ({
			...state, error: true
		}),
	}
}, initialState);


export default UserReducer;
