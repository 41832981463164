import { ofType } from "redux-observable"
import { merge, of } from 'rxjs'
import { switchMap, catchError, map } from 'rxjs/operators'
import {
  NEWS_LOAD,
  newsLoadOk,
} from '../actions/news'
import UserApi from '../../api/user.api'

const NewsLoadEpic = action$ => action$.pipe(
  ofType(NEWS_LOAD),
  switchMap(() => {
    const api = new UserApi();
    return api.news()
      .pipe(
        map(news => {
          return newsLoadOk(news)
        }),
        catchError(error => {
          return of(newsLoadOk(error))
        })
      )
  })
)



export default function NewsEpics(action$){
  return merge(
    NewsLoadEpic(action$),
  )
};
