import { createActions } from 'redux-actions';

export const NEWS_LOAD = 'NEWS_LOAD';
export const NEWS_LOAD_OK = 'NEWS_LOAD_OK';


const actionsCreator = createActions(
  NEWS_LOAD,
  NEWS_LOAD_OK,
);

export const {
  newsLoad,
  newsLoadOk,
} = actionsCreator;
