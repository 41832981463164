import { handleActions } from 'redux-actions';
import testimonies from '../../constants/data/testimonies';

const initialState = {
	testimonies
};
const TestimonyReducer = handleActions({
	TESTIMONIES_LOAD_OK: {
		next: (state, action) => ({
			...state, testimonies: action.payload
		}),
		throw: (state, action) => ({
			...state, error: {message: action.payload.message, status: action.payload.status}
		}),
	}
}, initialState);


export default TestimonyReducer;