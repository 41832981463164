export const ACTION = {
	LOGIN: 'LOGIN',
	SHOW_INFO_BUSSINESS: 'SHOW_INFO_BUSSINESS',
	OPEN_WEEKLY_TEST: 'OPEN_WEEKLY_TEST',
	WEEKLY_TEST_FAILED: 'WEEKLY_TEST_FAILED',
	WEEKLY_TEST_SUCCESSFUL: 'WEEKLY_TEST_SUCCESSFUL',
	VIEW_RANKING: 'VIEW_RANKING',
  CLICK_PROFILE: 'CLICK_PROFILE',
  CLICK_AVANCE: 'CLICK_AVANCE',
  CLICK_CAMPAIGN: 'CLICK_CAMPAÑA',
  CLICK_PRODUCTS: 'CLICK_PRODUCTOS',
  CONDITIONS_CAMPAIGN: 'CONDICION_CAMPAÑA'
};
export const CATEGORY = {
	URPI: 'URPI',
  WEB: 'WEB',
  CAMPAIGN: 'CAMPAÑAS'
};
export const ANALYTICS = {
  PROFILE: 'profile',
  AVANCE: 'avance',
  CAMPAIGN: 'campaña',
  PRODUCTS: 'productos',
	INSURANCE_DESCRIPTION: '/description',
	INSURANCE_DESCRIPTION_EXAMPLE1: 'insurance/description/example-1',
	INSURANCE_DESCRIPTION_EXAMPLE2: 'insurance/description/example-2',
	INSURANCE_COVERAGE: '/coverage',
	INSURANCE_EXCLUTIONS: '/exclutions',
	INSURANCE_HOWTOUSE: '/how-to-use',
	MODAL_EFECTIVITY: 'efectividad',
	MODAL_SALEMISSION: 'mision-venta',
	MODAL_GIFT: 'gift/how-to-change'
};
